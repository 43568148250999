// IMPORTANTE: Atualize também o arquivo src/helpers/colors.js
// COLOR PALETTE
// Backgrounds
$mainClr: #54D3C2; /* buttons */
$mainClrDark: #052924; /* buttons */
$mainClrShadow: rgba(84, 211, 194, 0.39); /* buttons */
$grayDark: #27292f; /* box background */
$grayDarkTransp: rgba(69, 90, 100, 0.8); /* box background */
$gray: #acacac; /* buttons */
$grayLight: #bdbdbd;

  // Text colors
$textDark: #212121; /* titles */
$textGreyDark: #3D3D3D; /* subtitles */
$textDefault: #828282; /* secondary texts */
$textGreyLight: #4d6672; /* labels */
$textLight: #e0e0e0; /* footer */
$bgBody: #fafafa;

// Bootstrap palette
$primary: #54D3C2;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #066775;
$light: #f8f9fa;
$dark: #343a40;
$white: #FFFFFF;
$black: #000;

$mainClr: var(--primary); /* buttons */
$mainClrDisabled: rgba(130, 135, 138, 0.8); /* disabled button */
$mainClr2: var(--primary2); /* buttons */

// Font Types
@font-face {
  src: url("/assets/font/roboto/Roboto-Regular.ttf");
  font-family: Roboto, sans-serif;
}

@font-face {
  src: url("fonts/Raleway-Bold.ttf");
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

@font-face {
  src: url("fonts/Raleway-ExtraBold.ttf");
  font-family: Raleway, sans-serif;
  font-weight: 800;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("fonts/fa-brands-400.eot");
  src: url("fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("fonts/fa-brands-400.woff2") format("woff2"), url("fonts/fa-brands-400.woff") format("woff"), url("fonts/fa-brands-400.ttf") format("truetype"), url("fonts/fa-brands-400.svg#fontawesome") format("svg"); }

@font-face {
font-family: 'Font Awesome 5 Free';
font-style: normal;
font-weight: 400;
font-display: auto;
src: url("fonts/fa-regular-400.eot");
src: url("fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("fonts/fa-regular-400.woff2") format("woff2"), url("fonts/fa-regular-400.woff") format("woff"), url("fonts/fa-regular-400.ttf") format("truetype"), url("fonts/fa-regular-400.svg#fontawesome") format("svg"); }


@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("fonts/fa-solid-900.eot");
  src: url("fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("fonts/fa-solid-900.woff2") format("woff2"), url("fonts/fa-solid-900.woff") format("woff"), url("fonts/fa-solid-900.ttf") format("truetype"), url("fonts/fa-solid-900.svg#fontawesome") format("svg"); }


$titleFnt: Raleway, sans-serif;
$mainFnt: Roboto, sans-serif;

$FAbrands: 'Font Awesome 5 Brands';
$FAregular: 'Font Awesome 5 Free';
