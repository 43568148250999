.Toaster {
  .Toaster__alert {
    span {
      &.toast-content {
        margin-left: 5px;
      }
    }

    .toast-icon {
      margin-top: 4px;
    }
  }
}
