/* Body */

body {
  font-family: $mainFnt;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Heading */
h1, h2, h3, h4, h5, h6 {
  font-family: $titleFnt;
  font-weight: 800;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0;
  text-transform: uppercase;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5, p {
  font-size: 14px;
}

h6 {
  font-size: 10px;
  text-transform: uppercase;
}

p {
  font-size: 14px;
}

a,
a:hover {
  text-decoration: none;
}

p, a {
  line-height: 1rem;
}

p a {
  color: $mainClr !important;

  &:hover {
    text-decoration: underline;
  }
}

i {
  //font-size: 18px;
  color: $grayLight;

  span {
    display: none;
  }
}

svg {
  fill: $grayLight;
  stroke: $grayLight;
  circle: $grayLight;
  path: $grayLight;
}

.mainClr {
  color: $mainClr;
}

.grayDark {
  color: $grayDark;
}

.gray {
  color: $gray;
}

.grayLight {
  color: $grayLight;
}

.textDark {
  color: $textDark;
}

.textGreyDark {
  color: $textGreyDark;
}

.textGreyLight {
  color: $textGreyLight;
}

.textDefault {
  color: $textDefault;
}

.textLight {
  color: $textLight;
}

.bgBody {
  color: $bgBody;
}

.textInfo{
  color: $info;
}

.textLarge{
  font-size: 18px;
}

.first-capital-letter::first-letter{
  text-transform: capitalize !important;
}