/* Header */

// banner header
.hero {
    overflow: hidden;
}
.steps {
  .header {
    background-position: center center;
    background-repeat: no-repeat;
    height: 50vh;
    box-sizing: border-box;
    background-size: cover;
    padding-bottom: 70px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-end;

    .logo-header {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span.logo {
        text-align: center;
        margin-bottom: 0;

        img {
          display: inline-block;
          width: 125px;
          border: solid rgba(255, 255, 255, 0.0) 15px;
          background-color: rgba(255, 255, 255, 0.75);
          backdrop-filter: blur(2px);
          border-radius: 10px;
          position: relative;
          bottom: -30px;
          z-index: 2;
          left: 5%;
          box-shadow: -1px 3px 8px 3px rgba(0, 0, 0, 0.11);
        }
      }

      .country-options {
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding-top: 1rem;

        div {
          text-align: center;
          margin: 0 1rem;

          span.btn-header-actions {
            width: 80px;
            background-color: $grayDark;
            color: $mainClr;

            i {
              color: $mainClr;
              width: 20px;
              text-align: center;
              transition: inherit;
            }

            &:hover,
            &:hover i {
              color: $white;
              background-color: $mainClr;
            }
          }

          .languages-selection {
            .languages {
              background-color: $grayDarkTransp;
              padding: 0;
              margin-top: -.1rem;
              position: absolute;
              width: 150px;
              margin-left: 0;

              .language-list {
                text-align: left;
                margin: 0;

                span {
                  color: $white;
                  display: block;
                  width: 100%;
                  margin: 0;
                  padding: .5rem;
                  text-align: left;
                  cursor: pointer;

                  &:hover {
                    background-color: $mainClr;
                  }
                }
              }
            }
          }
        }
      }
    }
    .container {
      background: transparent;
    }
    .container.stepper-logo-wrapper {
        display: flex;
    }
    .container.country-options-wrapper {
        position: absolute;
        top: 0;
    }
  }

  // Stepper
  .md-stepper-horizontal {
    display: table;
    margin: 0;
    width: 80%;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(2px);
    box-shadow: 1px 5px 8px 3px rgba(0, 0, 0, 0.18);
    position: absolute;
    bottom: 25px;

    i {
      color: $white;
      font-size: 24px;
      margin-right: 0;
    }

    .md-step {
      display: table-cell;
      position: relative;
      padding: 14px 20px;

      span {
        display: none;
      }

      &:hover,
      &:active {
        &:first-child,
        &:first-child:active {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }

        &:last-child,
        &:last-child:active {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }

      &:active {
        :active {
          border-radius: 50px;
        }
      }

      &:first-child:active {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child:active {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:first-child .md-step-bar-left,
      &:last-child .md-step-bar-right {
        display: none;
      }

      .md-step-circle {
        width: 35px;
        height: 35px;
        margin: 0 auto;
        background-color: $mainClr;
        border-radius: 50%;
        text-align: center;
        line-height: 46px;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active .md-step-circle {
        background-color: $success;
      }

      &.done {
        .md-step-circle:before {
          font-weight: 100;
        }
      }

      &.editable {
        .md-step-circle {
          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        .md-step-circle:before {
          font-weight: 100;
        }
      }

      .md-step-title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }

      .md-step-title,
      .md-step-optional {
        text-align: center;
        color: rgba(0, 0, 0, .26);
      }

      &.active .md-step-title {
        font-weight: 600;
        color: rgba(0, 0, 0, .87);
      }

      .active.done .md-step-title,
      .active.editable .md-step-title {
        font-weight: 600;
      }

      .md-step-optional {
        font-size: 12px;
      }

      &.active .md-step-optional {
        color: rgba(0, 0, 0, .54);
      }

      .md-step-bar-left,
      .md-step-bar-right {
        position: absolute;
        top: 32px;
        height: 1px;
        border-top: 2px solid $grayLight;
      }

      .md-step-bar-right {
        right: 0;
        left: 60%;
        margin-left: 20px;
      }

      .md-step-bar-left {
        left: 0;
        right: 60%;
        margin-right: 20px;
      }
    }


    &.orange .md-step.active .md-step-circle {
      background-color: $mainClr;
    }

    &.md-step.active .md-step-circle {
      background-color: rgb(33, 150, 243);
    }
  }

  // Country Options
  .country-options {
    padding-top: 25px;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    .languages-selection, .currency-selector {
        margin-left: 15px;

      .btn {
          background: $mainClr;
          border-radius: 100px;
          color: #fff;
          font-family: $titleFnt;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1px;
          padding: 5px 20px;
      }

      .btn svg {
          margin-right: 4px;
      }

    }
  }
}

.material-icons {
  color: $white;
}

