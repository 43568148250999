/* Footer */

footer {
  hr {
    margin-top: 0;
    background-color: $grayDark;
    border-top: solid 1px $grayLight;
  }

  p {
      font-size: 15px;
      letter-spacing: 0.7px;
      line-height: 28px;
      opacity: 0.9;
      font-weight: 400;
      font-family: $titleFnt;
  }

  span.icon2 {
    display: inline-block;
    margin-right: 5px;

    &:before {
      font-size: 24px;
    }
  }

  li.list-inline-item {
      font-size: 20px;
  }

  .greydark{
    h4, h5 {
      margin-bottom: 20px;
      font-weight: 700;
      letter-spacing: 4px;
    }

    .photo-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      img {
        display: block;
        margin-bottom: 12px;
        width: 130px;
        height: auto;
        object-fit: cover;
      }
    }

    .about {
      &.v-align {
        padding-bottom: 10px;
      }
    }

    .map-container {
      &.card {
        height: 87%;
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .credit-cards {
      i {
        font-size: 22px;
      }
    }

    .logo-footer {
      height: 32%;
    }
  }
  .secure::before {
      content: '\f023';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: rgba(255, 255, 255, 0.46);
      font-size: 12px;
      margin-right: 10px;
  }
}
